// global
const RESPONSE_STATUS_ERROR = 'error'
const RESPONSE_STATUS_SUCCESS = 'success'

const TYPE_ADD = 'add'
const TYPE_EDIT = 'edit'
const TYPE_VIEW = 'view'
// end global

const PAYMENT_STATUS_ARRAY = {
  1: 'Menunggu Pembayaran',
  2: 'Pembayaran Sukses',
  3: 'Transaksi Kadaluarsa',
  4: 'Transaksi Dibatalkan'
}

const PAYMENT_STATUS_ARRAY_TEXT_COLOR = {
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'danger'
}

const XENDIT_PAYMENT_STATUS_ARRAY_TEXT_COLOR = {
  'pending': 'warning',
  'paid': 'success',
  'expired': 'danger',
}

const XENDIT_PAYMENT_STATUS_ARRAY = {
  'pending': 'Pending',
  'paid': 'Paid',
  'expired': 'Expired',
}

const PROMO_TYPE_PERCENT = 'PERCENT'
const PROMO_TYPE_PRICE = 'PRICE'

const AUTH_ROLE_SUPER_ADMIN = 3
const ROLE_ADMIN = 1
const ROLE_STARTER = 2
const ROLE_CUSTOMER_ADMIN = 4
const ROLE_SEO_ADMIN = 5
const ROLE_INSTRUCTIONAL_DESIGNER_ADMIN = 6

const PARTNER_STATUS_ARRAY_TEXT_COLOR = {
  'penalty': 'warning',
  'active': 'success',
  'inactive': 'secondary',
  'out': 'danger',
}

const PAYOUT_STATUS_ARRAY_TEXT_COLOR = {
  'queued': 'warning',
  'approved': 'success',
  'processed': 'info',
  'completed': 'success',
  'failed': 'danger',
  'rejected': 'danger',
}

const PROJECT_STATUS_FINISH = 'finish'
const PROJECT_STATUS_ON_REVIEW = 'on review'
const PROJECT_STATUS_ONGOING = 'ongoing'
const PROJECT_STATUS_ARRAY_TEXT_COLOR = {
  'pending': 'danger',
  'ongoing': 'warning',
  'on review': 'info',
  'finish': 'success',
}

const CURRENT_PAGE_ARRAY = {
  'add': 'Add',
  'edit': 'Edit',
  'view': 'View',
}


// service
const SERVICE_STATUS_DRAFT = "DRAFT"
const SERVICE_STATUS_ACTIVE = "ACTIVE"
const SERVICE_STATUS_INACTIVE = "INACTIVE"
const SERVICE_STATUS_ARRAY_TEXT_COLOR = {
  'ACTIVE': 'success',
  'INACTIVE': 'danger',
  'DRAFT': 'warning',
}

// service workflow
const DYNAMIC_WORKFLOW_CONFIRMATION = "CONFIRMATION"
const DYNAMIC_WORKFLOW_UPLOAD_FILE = "UPLOAD_FILE"
const DYNAMIC_WORKFLOW_CHOICE = "CHOICE"
const DYNAMIC_WORKFLOW_APPROVAL = "APPROVAL"
const DYNAMIC_WORKFLOW_TEXT = 'TEXT';
const DYNAMIC_WORKFLOW_GOOGLE_APP_TEXT_LINK = "GOOGLE_APP_TEXT_LINK";

const DYNAMIC_WORKFLOW_ACTOR_CLIENT = "CLIENT"
const DYNAMIC_WORKFLOW_ACTOR_PARTNER = "PARTNER"

const DYNAMIC_WORKFLOW_VALUE_REJECT = "REJECT"
const DYNAMIC_WORKFLOW_VALUE_APPROVE = "APPROVE"

const PARTNER_PAYMENT_TYPE_PRICE_X_COMMISSION = "PRICE_X_COMMISSION"
const PARTNER_PAYMENT_TYPE_FIXED = "FIXED"
const PARTNER_PAYMENT_TYPE_FIXED_X_COMMISSION = "FIXED_X_COMMISSION"
// end service

const PROJECT_BRIEF_INDUSTRY_OTHER = "Other"

export default {
  RESPONSE_STATUS_ERROR,
  RESPONSE_STATUS_SUCCESS,
  TYPE_ADD,
  TYPE_EDIT,
  TYPE_VIEW,
  PAYMENT_STATUS_ARRAY,
  PAYMENT_STATUS_ARRAY_TEXT_COLOR,
  PROMO_TYPE_PERCENT,
  PROMO_TYPE_PRICE,
  AUTH_ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_CUSTOMER_ADMIN,
  ROLE_SEO_ADMIN,
  ROLE_INSTRUCTIONAL_DESIGNER_ADMIN,
  PARTNER_STATUS_ARRAY_TEXT_COLOR,
  CURRENT_PAGE_ARRAY,
  ROLE_STARTER,
  PROJECT_STATUS_ARRAY_TEXT_COLOR,
  PROJECT_STATUS_FINISH,
  PROJECT_STATUS_ON_REVIEW,
  PROJECT_STATUS_ONGOING,
  SERVICE_STATUS_ARRAY_TEXT_COLOR,
  SERVICE_STATUS_DRAFT,
  SERVICE_STATUS_ACTIVE,
  SERVICE_STATUS_INACTIVE,
  DYNAMIC_WORKFLOW_CONFIRMATION,
  DYNAMIC_WORKFLOW_UPLOAD_FILE,
  DYNAMIC_WORKFLOW_CHOICE,
  DYNAMIC_WORKFLOW_APPROVAL,
  DYNAMIC_WORKFLOW_TEXT,
  DYNAMIC_WORKFLOW_GOOGLE_APP_TEXT_LINK,
  DYNAMIC_WORKFLOW_ACTOR_CLIENT,
  DYNAMIC_WORKFLOW_ACTOR_PARTNER,
  DYNAMIC_WORKFLOW_VALUE_REJECT,
  DYNAMIC_WORKFLOW_VALUE_APPROVE,
  PARTNER_PAYMENT_TYPE_PRICE_X_COMMISSION,
  PARTNER_PAYMENT_TYPE_FIXED,
  PARTNER_PAYMENT_TYPE_FIXED_X_COMMISSION,
  PAYOUT_STATUS_ARRAY_TEXT_COLOR,
  XENDIT_PAYMENT_STATUS_ARRAY_TEXT_COLOR,
  XENDIT_PAYMENT_STATUS_ARRAY,
  PROJECT_BRIEF_INDUSTRY_OTHER,
}
